:root {
  --font-min: 10px;
  --font-smallest: 11px;
  --font-small: 12px;
  --font-regular: 14px;
  --font-medium: 15px;
  --font-default: 16px;
  --font-large: 18px;
  --font-larger: 28px;
  --font-largest: 32px;
  --font-title: 38px;
  --font-max: 40px;
  --font-max2: 48px;

  --default-font-color: #061938;
  --white-font-color: #ffffff;
  --bg: #ffffff;
  --primary-color: #191e4c;
  --secondary-color: #0d008c;
  --tertiary-color: #ff7c2e;
  --leve-color: #1d2680;
  --leve-primary: #4350CC;
  --font-color: #313456;
  --backgroundWhite: #fff;
  --default-color: #426fa1;
  --progress-bar-backrgound: #1d2680;

  --default-dark-gray: #333;
  --dark-gray: #444444;
  --regular-gray: #555555;
  --medium-gray: #7d8073;
  --default-gray: #d9d9d9;
  --light-gray: #e6e6e6;
  --border-gray: #cfd2d4;
  --gray-lighter: #F5F8FA;
  --gray-darker: #657787;

  --neutral-mid: #505f79;
  --non-operators: #a31700;
  --operators: #eb5600;
  --wind: #00a2c2;
  --sun: #cc8500;
  --security: #5ba63a;
  --leadership: #4285f4;
  --digital-color: #B6ED68;

  --primary-color-hover: #191e4c;
  --secondary-color-hover: #b4d783;
  --danger-color: #de2443;
  --alert-color: #ffc107;
  --success-color: #28a745;
}

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  outline: none;
}

button * {
  pointer-events: none;
}

.content {
  max-width: 1300px;
  width: 100vw;
  margin: 0 auto;
  padding: 0 15px;
}

@keyframes entering {
  from { top: -30px }
  to { top: 0px }
}

.global-alert {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 20px;
  width: calc(100vw - 16px);
  color: white;
  transition: all 0.3s;
  z-index: 999;
  animation: entering 0.5s ease-in;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.global-alert span {
  display: block;
  width: calc(100vw - 40px);
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.global-alert button {
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: white;
}

.global-alert.error {
  background: var(--danger-color);
}

.global-alert.alert {
  background: var(--alert-color);
}

.global-alert.success {
  background: var(--success-color);
}

.category {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.category > img {
  width: 20px;
}

#global-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;
  padding: 20px;
  transition: all 0.3s;

  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 16px solid #bcbcbc;
  border-radius: 50%;
  border-top: 16px solid var(--leve-color);
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.react-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 999;

  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.react-modal-overlay::-webkit-scrollbar {
  display: none;
}

.react-modal-content {
  outline: none;
}

body {
  background: var(--bg);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
